<template>
  <div v-if="!item.hidden">
    <!-- <template
      v-if="
        hasOneShowingChild(item.subFunctions, item) &&
        (!onlyOneChild.subFunctions || onlyOneChild.noShowingChildren)
      "
    ></template> //只有一个子节点不展示下拉选项 -->
    <template v-if="hasOneShowingChild(item.subFunctions, item)">
      <app-link :to="'/' + onlyOneChild.menuUrl">
        <el-menu-item :index="'/' + onlyOneChild.menuUrl" :class="{ 'submenu-title-noDropdown': !isNest }">
          <template slot="title">
            <img :src="onlyOneChild.icon" alt="" />
            <span class="menu-title" :title="hasTitle(item.name)">- {{ item.name }}</span>
          </template>
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else ref="subMenu" :index="item.id + ''" popper-append-to-body>
      <template slot="title">
        <img :src="onlyOneChild.icon" alt="" />
        <span class="menu-title" :title="hasTitle(item.name)">{{ item.name }}</span>
      </template>
      <sidebar-item v-for="route in item.subFunctions" :key="route.id" :item="route" class="nest-menu" />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path';
import { isExternal } from '@/utils/validate';
import Item from './Item';
import AppLink from './Link';
import FixiOSBug from './FixiOSBug';

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasTitle(title) {
      if (title.length > 5) {
        return title;
      } else {
        return '';
      }
    },
    hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = [];
      }
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      // if (showingChildren.length === 1) {
      //   return true;
      // }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    }
  }
};
</script>
