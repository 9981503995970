<template>
  <div
    :class="{ 'has-logo': showLogo }"
    :style="{
      backgroundColor: '#304156'
    }"
  >
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#304156"
        text-color="#bfcbd9"
        :unique-opened="true"
        active-text-color="#11A983"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in sidebarRouters" :key="route.id" :item="route" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/assets/styles/variables.scss';
import { menuTreeApi } from '@/api/api';

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['sidebar']),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  data() {
    return {
      sidebarRouters: []
    };
  },
  created() {
    this.getMenuTree();
  },
  methods: {
    getMenuTree() {
      menuTreeApi().then((res) => {
        this.sidebarRouters = res;
      });
    }
  }
};
</script>
